import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CommercialPartner } from '../../../partners/types/partners-types'
import { SelectedHubPartner } from './create-account-user-modal-component-types'
import { AccountUsersService } from '../../services/account-users.service'
import { ErrorListArrayMessageParser } from '../../../../core/services/error-list-array-message-parser.service'
import { PartnersDataService } from '../../../../core/services/partners-data.service'
import { FormValidatorHelper } from '../../../../shared/helpers/form-validator.helper'
@Component({
    selector: 'app-create-account-user-modal',
    templateUrl: './create-account-user-modal.component.html',
    styleUrls: ['./create-account-user-modal.component.scss'],
})
export class CreateAccountUserModalComponent implements OnInit, OnDestroy {
    @Input() partner!: CommercialPartner

    hubPartnersList: CommercialPartner[] = []
    selectedPartner: SelectedHubPartner | null = null
    @Input() accountUserTypes: {
        name: string
        id: string
        isInternal: boolean
    }[] = []

    form!: UntypedFormGroup
    masks = {
        cpf: '000.000.000-00',
        cellPhone: '(00) 00000-0000',
    }

    isCreatingUser = false

    selectedUserType: {
        name: string
        id: string
        isInternal: boolean
    } | null = null

    onDestroyNotifier$ = new Subject()

    documentInputFieldConfigs = { placeholder: '___.___.___-__', mask: this.masks.cpf }
    phoneInputFieldConfigs = { placeholder: '(__) ____-____', ngMask: this.masks.cellPhone }

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private accountUsersService: AccountUsersService,
        private errorListMessageParser: ErrorListArrayMessageParser,
        private activeModal: NgbActiveModal,
        private partnersDataService: PartnersDataService,
    ) {}

    ngOnInit(): void {
        this.createNewAccountUserForm()
        this.registerUserTypeValueChangeObserver()
        if (!this.partner) this.getHubPartnersList()
    }

    private createNewAccountUserForm() {
        this.form = this.formBuilder.group({
            user_name: [null, [FormValidatorHelper.required, FormValidatorHelper.maxLength(20)]],
            user_lastname: [
                null,
                [FormValidatorHelper.required, FormValidatorHelper.maxLength(20)],
            ],
            document_number: [
                null,
                [FormValidatorHelper.required, FormValidatorHelper.cpfValidation],
            ],
            user_email: [null, [FormValidatorHelper.required, FormValidatorHelper.email]],
            user_phone: [null, FormValidatorHelper.required],
            user_type_id: [null, FormValidatorHelper.required],
            sales_partner_id: [null, FormValidatorHelper.required],
            hub_partner_id: [null, FormValidatorHelper.required],
        })
    }

    private registerUserTypeValueChangeObserver() {
        this.form
            .get('user_type_id')
            ?.valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe(userTypeId => {
                if (userTypeId) {
                    this.defineFormRulesBySelectedUserType(userTypeId)
                }
            })
    }

    private defineFormRulesBySelectedUserType(userTypeId: string) {
        const selectedUserType = this.getSelectedUserType(userTypeId)
        this.selectedUserType = selectedUserType

        if (selectedUserType?.isInternal) {
            this.form.get('sales_partner_id')?.setValidators(null)
            this.form.get('sales_partner_id')?.setValue('Não associado')

            this.form.get('hub_partner_id')?.setValidators(null)
            this.form.get('hub_partner_id')?.setValue(null)
        } else {
            this.form.get('sales_partner_id')?.setValidators([FormValidatorHelper.required])
            this.form.get('sales_partner_id')?.setValue(null)

            this.form.get('hub_partner_id')?.setValidators([FormValidatorHelper.required])
            this.form.get('hub_partner_id')?.setValue(this.partner ? this.partner.id : null)
            this.selectedPartner = null
        }
    }

    private getSelectedUserType(userTypeId: string) {
        return this.accountUserTypes.find(type => type.id === userTypeId) ?? null
    }

    shouldShowPartnerLiveSelect() {
        return this.selectedUserType && !this.selectedUserType.isInternal && !this.partner
    }

    getHubPartnersList() {
        this.partnersDataService.getHubPartnersList().subscribe(
            data => {
                if (data.success) {
                    //@ts-ignore
                    this.hubPartnersList = data.commercial_partners_list
                } else {
                    if (data.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(data.errorList)
                    }
                    console.log(data)
                }
            },
            response => {
                this.toastr.error('Não foi possível carregar lista de parceiros')
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Não foi possível carregar lista de parceiros',
                )
            },
        )
    }

    getSelectedHubPartner(selectedHubPartner: SelectedHubPartner) {
        this.selectedPartner = selectedHubPartner
        this.form.get('hub_partner_id')?.setValue(selectedHubPartner.hub_partner_id)
        this.form.get('sales_partner_id')?.setValue(null)
    }

    shouldShowBusinessUnitySelect() {
        if (this.partner) return true

        return this.selectedUserType && !this.selectedUserType.isInternal && this.selectedPartner
    }

    getBusinessUnities() {
        if (this.partner) return this.partner.business_unities ?? []
        return this.selectedPartner?.business_unities ?? []
    }

    private finishUserCreation() {
        this.toastr.success('Usuário convidado com sucesso')
        this.activeModal.close({ userCreated: true })
    }

    removeMasks(value: string) {
        return value.replace(/[().-]/g, '').replace(/ /g, '')
    }

    sanitizeDocumentAndPhoneMasksBeforeSubmit() {
        this.form
            .get('document_number')
            ?.setValue(this.removeMasks(this.form.getRawValue().document_number))

        this.form.get('user_phone')?.setValue(this.removeMasks(this.form.getRawValue().user_phone))
    }

    private createAccountUser() {
        this.sanitizeDocumentAndPhoneMasksBeforeSubmit()
        this.isCreatingUser = true
        this.accountUsersService.createAccountUserPreRegister(this.form.getRawValue()).subscribe(
            data => {
                if (data.success) {
                    if (data.userExistsOnAuthenticationUserService) {
                        this.toastr.error('Este usuário já possui acesso registrado no Partner Hub')
                    } else {
                        this.finishUserCreation()
                    }
                } else {
                    const { errorList } = data

                    this.errorListMessageParser.showErrorListMessageOrDefault(
                        errorList,
                        'Falha não mapeada ao tentar criar o usuário',
                    )
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar criar o usuário',
                )
            },
            () => (this.isCreatingUser = false),
        )
    }

    public handleCreateNewUser() {
        if (!this.form.valid) {
            this.toastr.error('Preencha todos os campos obritatórios para prosseguir com a criação')
            return
        }

        this.createAccountUser()
    }

    ngOnDestroy() {
        this.onDestroyNotifier$.next({})
    }
}
