import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import moment from 'moment/moment'
import { ToastrService } from 'ngx-toastr'
import { ManageLoggedUser } from '../../../../authentication/services/manage-logged-user.service'
import { ConfirmModal } from '../../../../core/components/confirm-modal/confirm-modal.component'
import { ErrorListArrayMessageParser } from '../../../../core/services/error-list-array-message-parser.service'
import { HistoryModal } from '../../../../shared/components/history-modal/history-modal.component'
import { AccountUsersService } from '../../services/account-users.service'
import { AccountUser } from '../../types'

interface IBusinessDeveloper {
    name: string
    channel: string
    team: string
    is_team_leader: boolean
    bounded_to_account_user: boolean
    is_active: boolean
    history: IHistoryItem[]
    created_at: string
    updated_at: string
    id: string
}

interface IHistoryItem {
    done_by_account_user_id: string
    event_id: string
    event: string
    change_log: string
    created_at: string
}

interface IUserType {
    name: string | null
    id: string | null
    type_id: string | null
}

interface IEdition {
    isEditing: boolean
    accountUserInEdition: string | undefined
    businessDeveloper: {
        id: string | undefined
        name: string | undefined
    }
    userType: {
        selectedUserType: {
            id: string | undefined
            name: string | undefined
        }
    }
}

@Component({
    selector: 'app-account-user-list',
    templateUrl: './account-user-list.component.html',
    styleUrls: ['./account-user-list.component.scss'],
})
export class AccountUserListComponent implements OnInit {
    @Input() accountUsers: AccountUser[] = []
    @Input() accountUserTypes: IUserType[] = []
    @Input() amountOfAccountUsers = 0
    @Input() isFetchingData = false
    @Input() openedViaBusinessManagement = false

    @Output() onUpdatedAccountUser = new EventEmitter()

    isInternalUser: boolean = false

    loading: {
        isUpdatingAccountUser: boolean
        updatingAccountUserId: string | null
    } = {
        isUpdatingAccountUser: false,
        updatingAccountUserId: null,
    }

    edition: IEdition = {
        isEditing: false,
        accountUserInEdition: undefined,
        businessDeveloper: {
            id: undefined,
            name: undefined,
        },
        userType: {
            selectedUserType: {
                id: undefined,
                name: undefined,
            },
        },
    }

    bizDevsList: IBusinessDeveloper[] = []

    constructor(
        private modalService: NgbModal,
        private loggedUserService: ManageLoggedUser,
        private toastr: ToastrService,
        private accountUsersService: AccountUsersService,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getBizDevsList()

        this.isInternalUser = this.loggedUserService.getLoggedAccountUser().loggedUser.hub_partner_id === 'none'
    }

    private async getBizDevsList() {
        this.accountUsersService.getAllActivesBusinessDevelopers().subscribe(
            data => {
                if (data.success) this.bizDevsList = data.active_business_developers
                else if (data.errorList[0]) this.errorListMessageParser.showErrorListMessage(data.errorList)
            },
            error => {
                this.toastr.error('Não foi possível carregar lista de bizdevs')
                let responseBody = JSON.parse(error._body)

                if (responseBody.errorList[0]) {
                    this.errorListMessageParser.showErrorListMessage(responseBody.errorList)
                }
                console.error(responseBody)
            },
        )
    }

    public getStatusColor(accountUser: AccountUser) {
        const currentStatus = this.getAccountUserStatus(accountUser)

        return currentStatus === 'Ativo' ? '_color_primary' : '_color_danger'
    }

    public getAccountUserStatus(accountUser: AccountUser) {
        if (accountUser.pre_register_validated === undefined) {
            return 'Usuário antigo pendente de migração'
        }

        if (!accountUser.pre_register_validated) {
            return 'Aceite do convite ainda pendente'
        }

        if (accountUser.role_type === 'user-owner-partner') {
            if (!accountUser.terms_and_conditions) {
                return 'Aceite dos termos pendente'
            }

            if (
                accountUser.terms_and_conditions.partner_hub_terms_acceptance &&
                accountUser.terms_and_conditions.partner_program_terms_acceptance
            ) {
                return 'Ativo'
            }

            return 'Aceite do convite ainda pendente'
        }

        return 'Ativo'
    }

    private clearEdition() {
        this.edition.isEditing = false
        this.edition.accountUserInEdition = undefined
        this.edition.userType.selectedUserType = { id: undefined, name: undefined }
    }

    private clearLoading() {
        // @ts-ignore
        this.loading.updatingAccountUserId = null
        this.loading.isUpdatingAccountUser = false
    }

    public canReSendInvite(accountUser: AccountUser) {
        return accountUser.allow_send_new_pre_register_invitation
    }

    public canUpdateUserStatus(accountUser: AccountUser) {
        return !accountUser.pre_register_validated
    }

    private deactivateAccountUser(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.deactivateAccountUser(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    this.toastr.success('Usuário desativado com sucesso')
                    this.onUpdatedAccountUser.emit()
                } else {
                    if (data.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(data.errorList)
                    }
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar desativar o usuário',
                )
            },
            () => this.clearLoading(),
        )
    }

    public handleDeactivateAccountUser(accountUser: AccountUser) {
        if (this.loggedUserService.getLoggedAccountUser().loggedUser.account_user_email === accountUser.email) {
            this.toastr.warning('Não é possível desativar o próprio usuário')
            return
        }

        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
            centered: true,
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo desativar este usuário?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.deactivateAccountUser(accountUser)
            }
        })
    }

    public handleGetAccountUserHistory(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.getAccountUserHistory(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    this.showAccountHistoryModal(data.history.events, data.history.amount_of_history_events)
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.erro?.errorList,
                    'Falha não mapeada ao tentar obter o histórico do usuário',
                )
            },
            () => this.clearLoading(),
        )
    }

    private showAccountHistoryModal(translatedHistory: any, amountOfHistoryEvents: any) {
        const openHistoryModal = this.modalService.open(HistoryModal, {
            size: 'lg',
            centered: true,
        })
        openHistoryModal.componentInstance.translatedHistory = translatedHistory
        openHistoryModal.componentInstance.translatedAmountOfHistoryEvents = amountOfHistoryEvents
    }

    private updateAccountUserRole(accountUser: AccountUser) {
        if (accountUser.user_type_id === this.edition.userType.selectedUserType.id) return

        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id
        this.accountUsersService
            .updateAccountUserRole({
                account_user_id: accountUser.account_user_id,
                user_type_id: this.edition.userType.selectedUserType.id,
            })
            .subscribe(
                data => {
                    if (data.success) {
                        this.toastr.success('O tipo do usuário foi alterado com sucesso')
                        this.onUpdatedAccountUser.emit()
                    } else {
                        if (data.errorList[0]) {
                            this.errorListMessageParser.showErrorListMessage(data.errorList)
                        }
                        return
                    }
                },
                response => {
                    this.errorListMessageParser.showErrorListMessageOrDefault(
                        response?.error?.errorList,
                        'Falha não mapeada ao tentar alterar o tipo do usuário',
                    )
                },
                () => {
                    this.clearEdition()
                    this.clearLoading()
                },
            )
    }

    public handleConfirmUpdatingAccountUser(accountUser: AccountUser) {
        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
            centered: true,
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Confirmar alterações?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.updateAccountUserRole(accountUser)
                this.bindBizDevToAccount(accountUser)
            }
        })
    }

    private bindBizDevToAccount(accountUser: AccountUser) {
        if (!this.edition.businessDeveloper.id || this.edition.businessDeveloper.id === accountUser.business_developer_id) return

        this.accountUsersService
            .bindBusinessDeveloperToAccountUser({
                account_user_id: accountUser.account_user_id,
                business_developer_id: this.edition.businessDeveloper.id,
            })
            .subscribe(
                data => {
                    if (data.success) this.toastr.success('Bizdev associado ao usuário com sucesso')
                    else {
                        if (data.errorList[0]) {
                            this.errorListMessageParser.showErrorListMessage(data.errorList)
                        }
                    }
                },
                error => {
                    this.toastr.error('Erro ao associar bizdev ao usuário')
                    let responseBody = JSON.parse(error._body)
                    if (responseBody.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(responseBody.errorList)
                    }
                },
                () => {
                    this.clearEdition()
                    this.clearLoading()
                },
            )
    }

    public handleToggleEditAccountUser(accountUser: AccountUser) {
        this.edition.isEditing = !this.edition.isEditing

        if (!this.edition.isEditing) {
            this.edition.accountUserInEdition = undefined
            this.edition.businessDeveloper = { id: undefined, name: undefined }
            this.edition.userType.selectedUserType = { id: undefined, name: undefined }
        } else {
            this.edition.businessDeveloper = {
                id: accountUser.business_developer_id,
                name: accountUser.business_developer_name,
            }
            //@ts-ignore
            this.edition.userType.selectedUserType = {
                id: accountUser.user_type_id,
                name: accountUser.user_type_alias,
            }

            // @ts-ignore
            this.edition.accountUserInEdition = accountUser.account_user_id
        }
    }

    private resendInvitation(accountUser: AccountUser) {
        this.loading.isUpdatingAccountUser = true
        this.loading.updatingAccountUserId = accountUser.account_user_id

        this.accountUsersService.sendPreRegisterInvitation(accountUser.account_user_id).subscribe(
            data => {
                if (data.success) {
                    accountUser.allow_send_new_pre_register_invitation = false
                    accountUser.last_pre_register_invitation_formatted_date = moment().format('DD/MM/YYYY HH:mm:ss')
                    accountUser.last_pre_register_invitation_date = moment().format('YYYY-MM-DD HH:mm:ss')

                    this.toastr.success('Convite reenviado com sucesso')
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar enviar o novo convite',
                )
            },
            () => this.clearLoading(),
        )
    }

    public handleResendInvitation(accountUser: AccountUser) {
        if (!accountUser.allow_send_new_pre_register_invitation || !accountUser.account_user_id) {
            this.toastr.error('Não é permitido reenviar o convite deste usuário')
            return
        }

        const openConfirmModal = this.modalService.open(ConfirmModal, { size: 'sm' })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo reenviar o convite?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.resendInvitation(accountUser)
            }
        })
    }

    public handleUploadUserStatus(accountUser: AccountUser) {
        if (accountUser.pre_register_validated) {
            this.toastr.error('Não é possível atualizar este usuário')
            return
        }

        const userObj = {
            id: accountUser.account_user_id,
            email: accountUser.email,
        }

        this.accountUsersService.updateUserStatus(userObj).subscribe(
            data => {
                console.log(data)
                if (data.success) {
                    this.toastr.success('Usuário atualizado com sucesso')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    if (data.errorList[0]) {
                        this.errorListMessageParser.showErrorListMessage(data.errorList)
                    }
                }
            },
            response => {
                console.log(response)
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar atualizar o usuário',
                )
            },
        )
    }

    public isLoading(accountUser: AccountUser) {
        return this.loading.isUpdatingAccountUser && this.loading.updatingAccountUserId === accountUser.account_user_id
    }

    public isEditing(accountUser: AccountUser) {
        return this.edition.isEditing && this.edition.accountUserInEdition === accountUser.account_user_id
    }
}
