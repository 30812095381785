import { Component, Input, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CommercialPartner } from '../../../partners/types/partners-types'
import { CreateAccountUserModalComponent } from '../../components/create-account-user-modal/create-account-user-modal.component'
import { AccountUserType } from '../../services/account-user-type'
import { AccountUsersService } from '../../services/account-users.service'
import { AccountUser } from '../../types'
import { FilterOption, Search } from '../../../../shared/components/search/search-types'
import { ErrorListArrayMessageParser } from '../../../../core/services/error-list-array-message-parser.service'

enum AccountUsersSearchTypes {
    FullName = 'full_name',
    Email = 'email',
    UserType = 'user_type',
}

interface AccountUserTypeSimple {
    name: string
    id: string
    type_id: string
    isInternal: boolean
}

@Component({
    selector: 'app-account-users-page',
    templateUrl: './account-users-page.component.html',
    styleUrls: ['./account-users-page.component.scss'],
})
export class AccountUsersPageComponent implements OnInit {
    @Input() partner!: CommercialPartner
    @Input() openedViaBusinessManagement = false

    isFetchingUsers = false
    isFetchingUserTypes = false
    accountUsers: AccountUser[] = []
    accountUsersAmount = 0
    accountUserTypes: AccountUserTypeSimple[] = []

    filters: FilterOption[] = [
        {
            type: AccountUsersSearchTypes.FullName,
            label: 'Nome completo',
            isDefault: true,
            placeholder: '',
        },
        {
            type: AccountUsersSearchTypes.Email,
            label: 'E-mail',
            isDefault: true,
            placeholder: '',
        },
        {
            type: AccountUsersSearchTypes.UserType,
            label: 'Tipo de usuário',
            isDefault: false,
            placeholder: '',
            optionList: [],
        },
    ]

    pagination = {
        amountRegistersPerPage: 30,
        currentPage: 1,
        pageSizeOptionsList: [30, 60],
        totalOfRegisters: 0,
    }

    constructor(
        private accountUsersService: AccountUsersService,
        private modalService: NgbModal,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    ngOnInit(): void {
        this.getAccountUsers()
        this.getAccountUserTypes()
    }

    private buildSearchFilters(search: Search<string, AccountUsersSearchTypes> | null = null) {
        return {
            search_by_hub_partner_id: this.partner ? this.partner.id : null,
            search_by_user_name:
                search && search.type === AccountUsersSearchTypes.FullName ? search.value : null,
            search_by_user_email:
                search && search.type === AccountUsersSearchTypes.Email ? search.value : null,
            search_by_role_type:
                search && search.type === AccountUsersSearchTypes.UserType ? search.value : null,
            page_number: this.pagination.currentPage,
            registers_per_page: this.pagination.amountRegistersPerPage,
        }
    }

    private getAccountUserTypes() {
        this.isFetchingUserTypes = true
        this.accountUsersService.getUserTypes().subscribe(
            data => {
                const { success, account_users_types, errorList } = data
                if (success) {
                    this.updateUserTypes(account_users_types)
                } else {
                    this.errorListMessageParser.showErrorListMessageOrDefault(
                        errorList,
                        'Falha não mapeada ao tentar obter a listagem de tipos de usuário',
                    )
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar obter a listagem de tipos de usuário',
                )
            },
            () => (this.isFetchingUserTypes = false),
        )
    }

    updateUserTypes(accountUsersTypes: AccountUserType[]) {
        const userTypes = accountUsersTypes.map(userType => {
            return {
                id: userType.name,
                name: userType.alias,
                type_id: userType._id,
                isInternal: userType.client_type === 1,
            }
        })
        if (this.partner) {
            this.accountUserTypes = userTypes.filter(userType => !userType.isInternal)
        } else {
            this.accountUserTypes = userTypes
        }

        this.updateUserTypeFilterOptions()
    }

    private updateUserTypeFilterOptions() {
        this.filters.forEach(filter => {
            if (filter.type === AccountUsersSearchTypes.UserType) {
                filter.optionList = this.accountUserTypes
            }
        })
    }

    public getAccountUsers(search: Search<string, AccountUsersSearchTypes> | null = null) {
        this.isFetchingUsers = true
        this.accountUsersService.getAccountUsers(this.buildSearchFilters(search)).subscribe(
            data => {
                if (data.success) {
                    this.accountUsers = data?.account_users_data
                        ? (data?.account_users_data as AccountUser[])
                        : []
                    this.accountUsersAmount = data?.amount_of_registers
                        ? (data?.amount_of_registers as number)
                        : 0
                    this.pagination.totalOfRegisters = this.accountUsersAmount
                }
            },
            response => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response?.error?.errorList,
                    'Falha não mapeada ao tentar obter a listagem de usuários',
                )
            },
            () => (this.isFetchingUsers = false),
        )
    }

    private hasAccountUsersCreated() {
        return this.accountUsers && this.accountUsers.length > 0
    }

    private getAvailableUserTypesToCreateNewAccountUser(): {
        name: string
        id: string
        isInternal: boolean
    }[] {
        let accountUserTypesAvailable = [...this.accountUserTypes]
        const isFirstUserFlow = !this.hasAccountUsersCreated()
        if (isFirstUserFlow) {
            accountUserTypesAvailable = accountUserTypesAvailable.filter(
                userType => userType.id === 'user-owner-partner',
            )
        }
        return accountUserTypesAvailable.map(userType => {
            return { name: userType.name, id: userType.type_id, isInternal: userType.isInternal }
        })
    }

    public handleCreateNewUserModal() {
        const newUserModal = this.modalService.open(CreateAccountUserModalComponent, {
            centered: true,
            size: 'xl',
        })

        newUserModal.componentInstance.accountUserTypes =
            this.getAvailableUserTypesToCreateNewAccountUser()
        newUserModal.componentInstance.partner = this.partner

        newUserModal.result
            .then(result => {
                if (result?.userCreated) {
                    this.getAccountUsers()
                }
            })
            .catch(res => {})
    }

    public handleSearch(search: Search<string, AccountUsersSearchTypes>) {
        this.getAccountUsers(search)
    }

    public handleClearSearch() {
        this.getAccountUsers()
    }

    public onPageChange(nextPage: any) {
        this.pagination.currentPage = Number(nextPage)
        this.getAccountUsers()
    }

    public onPageSizeChange(newPageSize: any) {
        this.pagination.amountRegistersPerPage = Number(newPageSize)

        this.getAccountUsers()
    }

    public needAcceptTerms() {
        return (
            this.accountUsersAmount === 1 &&
            !this.partner?.terms_and_conditions?.partner_hub_terms_acceptance
        )
    }
}
