<div>
    <div class="emd-container__content">
        <div class="w-100 eu-display_flex eu-column" style="gap: 0.625rem">
            <div class="w-100 eu-display_flex eu-justify_space-between eu-align-items_center">
                <app-search-bar
                    [isFetchingData]="isFetchingUsers"
                    [filters]="filters"
                    [hideBarLabel]="true"
                    (onSearch)="handleSearch($event)"
                    (onClear)="handleClearSearch()"
                    style="width: 700px"
                >
                </app-search-bar>

                <button
                    class="emd-btn new-user-button"
                    [disabled]="isFetchingUsers || needAcceptTerms()"
                    (click)="handleCreateNewUserModal()"
                >
                    Criar Usuário
                </button>
            </div>
            <app-account-user-list
                [isFetchingData]="isFetchingUsers"
                [accountUsers]="accountUsers"
                [amountOfAccountUsers]="accountUsersAmount"
                [accountUserTypes]="accountUserTypes"
                [openedViaBusinessManagement]="openedViaBusinessManagement"
                (onUpdatedAccountUser)="getAccountUsers()"
            ></app-account-user-list>
            <pagination
                *ngIf="!isFetchingUsers && accountUsers && accountUsers.length"
                [size]="pagination.totalOfRegisters"
                [actualPage]="pagination.currentPage"
                [pageSizeOptionsList]="pagination.pageSizeOptionsList"
                (pageChange)="onPageChange($event)"
                (pageSizeChange)="onPageSizeChange($event)"
                [radius]="8"
            ></pagination>
        </div>
    </div>
</div>
